'use client';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useMenu } from '@/hooks/useMenu';
import { useManyPerms, usePerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import { raise } from '@/lib/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import type { ISubMenu } from '../data';
import NotificationIndicator from './notification-indicator';
import { SubMenuItem } from './sub-menu-item';

interface SubMenuProps {
  menu: ISubMenu;
  notifications?: Record<string, number | boolean | undefined>;
}

export function SubMenuSettings({ menu, notifications }: SubMenuProps) {
  // ------------------ Hooks ------------------
  const { data: self } = useGetSelf();
  const { isEditMode, sidebarSettings, setSidebarSettings } = useMenu();
  const pathname = usePathname();
  const modelName = useMemo(() => menu.modelName, [menu]);
  const { canView } = usePerms(modelName);
  const modelNames = menu.modelNames || [];
  const { perms } = useManyPerms(modelNames);

  // ------------------ Derived States ------------------
  const isPigelloUser =
    (self?.email?.endsWith('@pigello.se') && self?.emailVerified) ?? false;

  const hasNoPerms =
    perms.every((perm) => !perm.canView) && modelNames.length > 0;

  const isNested = menu.subMenus && menu.subMenus.length > 0;
  const hasNotificationsNested =
    notifications && notifications[menu.key] != null;

  const isActive = menu.path
    ? pathname.endsWith(menu.path.split('/').pop() || '')
    : false;

  // ------------------ Early Returns ------------------
  if (
    hasNoPerms ||
    (!canView && !!menu.modelName) ||
    menu.hidden ||
    (menu.pigelloUserOnly && !isPigelloUser)
  )
    return null;

  // ------------------ Render Nested Menu ------------------
  if (isNested) {
    return (
      <Accordion
        value={sidebarSettings.expandedItems?.[menu.key]}
        onValueChange={(values) => {
          setSidebarSettings((prev) => ({
            ...prev,
            expandedItems: {
              ...prev.expandedItems,
              [menu.key]: values,
            },
          }));
        }}
        className='w-full'
        type='multiple'
      >
        <AccordionItem
          className='grow border-none '
          value={menu.title}
          key={menu.key}
        >
          <AccordionTrigger
            disabled={sidebarSettings.menuVisibility?.[menu.key]}
            className={cn(
              'my-0.5 flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-left text-foreground transition-colors hover:no-underline',
              sidebarSettings.menuVisibility?.[menu.key] && 'opacity-50',
              !sidebarSettings.menuVisibility?.[menu.key] &&
                'hover:bg-muted-foreground/10'
            )}
          >
            <div className='flex w-full items-center gap-1'>
              {menu.icon}
              <div className='flex w-full items-center justify-between truncate text-sm font-normal text-foreground'>
                {menu.title}
                {hasNotificationsNested && !isEditMode && (
                  <NotificationIndicator count={notifications[menu.key]} />
                )}
              </div>
            </div>
          </AccordionTrigger>

          <AccordionContent className='my-1 pb-0'>
            <ul>
              {menu.subMenus
                ?.filter(
                  (subMenu) =>
                    !sidebarSettings.menuVisibility?.[subMenu.key] &&
                    (!subMenu.modelName ||
                      perms[modelNames.indexOf(subMenu.modelName)]?.canView)
                )
                .sort(
                  (a, b) =>
                    (sidebarSettings.menuOrder?.[menu.key]?.indexOf(a.key) ||
                      0) -
                    (sidebarSettings.menuOrder?.[menu.key]?.indexOf(b.key) || 0)
                )
                .map((subMenu) => (
                  <div
                    key={subMenu.key}
                    className='group/item flex items-start gap-1 pl-6'
                  >
                    {/* SubMenu Item */}
                    <SubMenuItem
                      key={`${subMenu.title}-${subMenu.path}`}
                      subMenu={subMenu}
                      notifications={notifications}
                      isPigelloUser={isPigelloUser}
                    />
                  </div>
                ))}
            </ul>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  }

  // ------------------ Render Single Menu Item ------------------
  const path = menu.path;
  if (!path) {
    raise(`menu path is undefined in SidebarMenuItem ${menu.title}`);
  }
  const hasNotificationsSingle = notifications && notifications[path] != null;

  return (
    <ul key={menu.key} className='grow'>
      <Link
        prefetch={false}
        aria-disabled={menu.disabled}
        className={cn(
          (menu.disabled || sidebarSettings.menuVisibility?.[menu.key]) &&
            'pointer-events-none opacity-50',
          isEditMode && 'pointer-events-none'
        )}
        href={path}
      >
        <li
          className={cn(
            'my-0.5 flex w-full items-center justify-between gap-2 truncate rounded px-2 py-1 text-left text-sm text-foreground transition-colors hover:bg-muted-foreground/10 ',
            isActive && 'bg-accent'
          )}
        >
          <div className='flex w-full items-center gap-1 transition-all duration-200 active:ml-0.5'>
            {menu.icon}
            <p className='text-foreground'>{menu.title}</p>
            {hasNotificationsSingle && !isEditMode && (
              <NotificationIndicator count={notifications?.[path]} />
            )}
          </div>
        </li>
      </Link>
    </ul>
  );
}
