'use client';

import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { RELEASE_NOTES } from './constants';

const RELEASE_NOTES_KEY = 'release-notes-latest-read-date';

export function useReleaseNoteReadStatus() {
  const [latestReadDate, setLatestReadDate] = useLocalStorage(
    RELEASE_NOTES_KEY,
    DateTime.now().minus({ days: 1 }).toISO()
  );

  const hasReadLatest = useMemo(() => {
    const latestReadDateInUnix =
      DateTime.fromISO(latestReadDate).toUnixInteger();

    // get latest date in release notes

    const latestReleaseNoteDate = RELEASE_NOTES.reduce(
      (acc, note) =>
        note.date.toUnixInteger() > acc.toUnixInteger() ? note.date : acc,
      RELEASE_NOTES[0].date
    );

    return latestReleaseNoteDate
      ? latestReleaseNoteDate.toUnixInteger() <= latestReadDateInUnix
      : false;
  }, [latestReadDate]);

  return {
    hasReadLatest,
    latestReadDate,
    setLatestReadDate,
  };
}
