import { DateTime } from 'luxon';
import type { IReleaseNote } from './release-notes-sheet-content';

export const RELEASE_NOTES: IReleaseNote[] = [
  {
    date: DateTime.fromISO('2025-03-20'),
    title: '2025-03-20',
    content: (
      <div className='flex flex-col space-y-4'>
        <div>
          <h3>Förbättringar av Betalnings- och Fakturahantering</h3>
          <ul className='list-disc space-y-2 text-sm'>
            <li>
              Åtgärdat ett problem där påminnelseavgifter kunde skickas ut
              felaktigt efter annullering.
            </li>
            <li>
              Förbättrat hanteringen av förskottsbetalningar och bokföringsdatum
              för att säkerställa korrekt redovisning.
            </li>
            <li>
              Åtgärdat ett problem där betalningar med nollbelopp felaktigt
              skapade bokföringsposter
            </li>
            <li>
              Lagt till möjlighet att styra bokföringsdatum för annullerade
              betalningar, vilket ger bättre flexibilitet i redovisningen
            </li>
          </ul>
        </div>

        <div>
          <h3>Rapportförbättringar</h3>

          <ul className='list-disc space-y-2 text-sm'>
            <li>
              Uppdaterat reskontrarapporten för att visa en mer komplett
              ekonomisk bild.
            </li>
            <li>Lagt till nya kolumner för förseningsavgifter.</li>
            <li>
              Förbättrat visningen av originalbelopp, avgifter och totalbelopp.
            </li>
            <li>Förbättrat tydligheten i beräkningen av kvarstående belopp.</li>
          </ul>
        </div>

        <div>
          <h3>Administrativa Förbättringar</h3>

          <ul className='list-disc space-y-2 text-sm'>
            <li>
              Förbättrat tydligheten i meddelanden gällande låsta
              bokföringsperioder.
            </li>
            <li>
              Förbättrat hanteringen av detaljerade loggar för bättre
              systemtransparens.
            </li>
            <li>
              Åtgärdat ett problem med betalningsmatchning där vissa betalningar
              behandlades felaktigt
            </li>
            <li>
              Förbättrat förslag på retroaktiv debitering för att vara mer
              träffsäkra och relevanta
            </li>
          </ul>
        </div>
      </div>
    ),
  },
];
