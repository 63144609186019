'use client';

import * as TabsPrimitive from '@radix-ui/react-tabs';
import { motion } from 'framer-motion';
import * as React from 'react';

import { cn } from '@/lib/cn';

const TabsGroupIdContext = React.createContext('');

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>((props, ref) => {
  const tabsGroupId = React.useId();

  return (
    <TabsGroupIdContext.Provider value={tabsGroupId}>
      <TabsPrimitive.Root ref={ref} {...props} />
    </TabsGroupIdContext.Provider>
  );
});

Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'inline-flex items-center justify-center gap-2 rounded-lg border border-background/90 bg-muted p-1 text-muted-foreground',
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const tabsGroupId = React.useContext(TabsGroupIdContext);

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'group relative inline-flex items-center justify-center whitespace-nowrap rounded-lg px-3 py-1.5 text-sm outline-none disabled:pointer-events-none disabled:opacity-50',
        className
      )}
      {...props}
    >
      {/* We can use the content primitive to get the internal radix state and
      only render the motion div, we need to remove some attributes though */}
      <TabsPrimitive.Content value={props.value} asChild>
        <motion.div
          id={undefined}
          role={undefined}
          tabIndex={-1}
          layoutId={tabsGroupId}
          aria-labelledby={undefined}
          className='absolute inset-0 z-0 border border-input bg-background shadow-input outline-1 outline-offset-2 outline-primary group-focus-visible:outline'
          style={{
            // must use inline style for framer to not distort
            borderRadius: '6px',
          }}
          transition={{ duration: 0.1 }}
        />
      </TabsPrimitive.Content>
      <div className='relative z-10'>{props.children}</div>
    </TabsPrimitive.Trigger>
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 data-[state=inactive]:hidden',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger };
