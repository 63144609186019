'use client';
import { SegmentSelector } from '@/components/sidebar/components/SegmentSelector';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
} from '@heroicons/react/16/solid';
import Link from 'next/link';

import {
  useGetOrganization,
  useGetOrganizationList,
} from '@/config/sales/organization/client';
import { useSystemHealth } from '@/hooks/use-system-health';
import { useGetSelf } from '@/hooks/useGetSelf';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import { MenuProvider } from '@/providers/menu-provider';
import type { Self } from '@/store/types';
import { useQueryClient } from '@tanstack/react-query';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { ActivityIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useQueryState } from 'nuqs';
import { useState } from 'react';
import { Icons } from '../icons';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { Logo } from '../ui/logo';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import SettingsNavigation from './components/SettingsNavigation';
import { DashboardNavigation } from './components/dashboard-navigation';
import { FloatingMenu } from './components/floating-menu';
import SystemStatus from './components/floating-menu/components/system-status';
import GlobalSearch from './components/globalSearch/GlobalSearch';
import SidebarLoader from './components/sidebar-loader';
import { WorkspaceSelector } from './components/workspace-selector';
import { useGetRoleSegmentCombination } from './hooks/use-get-role-segment-combination';

export function Sidebar({
  variant,
}: {
  variant: 'dashboard' | 'settings' | 'documents';
}) {
  const { data: self, isPending } = useGetSelf();
  const [systemStatusOpen, setSystemStatusOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    data: roleSegmentCombination,
    isLoading: isLoadingRoleSegmentCombination,
  } = useGetRoleSegmentCombination({
    enabled: !!self,
    staleTime: 60 * 5 * 1000,
  });

  const isRoleSegmentsDone =
    roleSegmentCombination &&
    roleSegmentCombination.length > 0 &&
    !isLoadingRoleSegmentCombination;

  const { data: systemHealth } = useSystemHealth();

  return (
    <>
      {systemStatusOpen && (
        <SystemStatus closeFunction={() => setSystemStatusOpen(false)} />
      )}

      <MenuProvider>
        <nav className='relative flex h-screen max-h-window-keeper-height w-full min-w-[270px] max-w-[300px] flex-col border-r bg-background p-0 shadow-[inset_0px_0px_10px_rgba(0,0,0,0.05)] dark:bg-muted'>
          {variant === 'settings' && (
            <div className=' border-b p-4 py-[19px]'>
              <div className='flex items-center justify-between'>
                <Link
                  prefetch={false}
                  href='/dashboard'
                  className='flex items-center bg-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 '
                >
                  <ChevronLeftIcon className='mr-2 size-5' />
                  <h2>Kontrollcenter</h2>
                </Link>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={() => setSystemStatusOpen(true)}
                      variant={'outline'}
                      size={'icon'}
                      className={cn('relative', {
                        'bg-yellow-200 animate-pulse':
                          !!systemHealth?.maintenance?.eta,
                        'bg-red-200 animate-pulse':
                          systemHealth?.maintenance?.active,
                      })}
                    >
                      <ActivityIcon className='size-4 text-foreground' />
                      <div
                        className={cn(
                          'absolute -right-1 -top-1 size-3 rounded-full border border-background',
                          {
                            'bg-green-500':
                              !systemHealth?.maintenance?.active &&
                              !systemHealth?.maintenance?.eta,
                            'bg-yellow-600 ': !!systemHealth?.maintenance?.eta,
                            'bg-red-500': systemHealth?.maintenance?.active,
                          }
                        )}
                      />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Systemstatus</TooltipContent>
                </Tooltip>
              </div>
            </div>
          )}
          {(variant === 'dashboard' || variant === 'documents') && (
            <div className='flex flex-col items-center gap-3 p-4 pb-2'>
              <div className='flex w-full items-start gap-4'>
                <Link
                  prefetch={false}
                  href={Urls.dashboard.home}
                  className='shrink-0'
                >
                  <Logo />
                </Link>
                <div className='grid w-full gap-2'>
                  {isLoadingRoleSegmentCombination && (
                    <div className='flex w-full items-center justify-between rounded-md border px-2 py-1'>
                      <span className='text-xs text-muted-foreground'>
                        Laddar...
                      </span>
                      <Icons.loader className='size-4 animate-spin' />
                    </div>
                  )}
                  <div className='flex flex-1 flex-col gap-2'>
                    {self && <OrganizationDropdown self={self} />}
                    {isRoleSegmentsDone && (
                      <WorkspaceSelector
                        isSideBar={true}
                        isOpen={open}
                        setIsOpen={setOpen}
                        roleSegmentCombination={roleSegmentCombination}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='flex w-full flex-row items-center justify-between'>
                <SegmentSelector />
              </div>
            </div>
          )}
          <div className='px-4'>
            <GlobalSearch hiddenInput={variant === 'settings'} />
          </div>

          <div className='relative min-w-full'>
            <div className='absolute inset-x-0 z-10 min-h-[12px] bg-gradient-to-b from-background to-transparent dark:from-muted'></div>
          </div>
          {isPending && <SidebarLoader />}
          <div className='overflow-y-scroll pb-28 scrollbar-hide'>
            {((self && variant === 'dashboard') || variant === 'documents') && (
              <DashboardNavigation />
            )}
            {self && variant === 'settings' && <SettingsNavigation />}
          </div>
          {self && <FloatingMenu isSettings={variant === 'settings'} />}
        </nav>
      </MenuProvider>
    </>
  );
}

function OrganizationDropdown({ self }: { self: Self }) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [filters, setFilters] = useQueryState('filter');
  const currOrgId = getCookie('organization_id');
  const { data, isPending: isLoadingOrganizations } = useGetOrganizationList({
    queryParams: {
      page: 1,
      pageSize: 25,
    },
  });

  const hasMoreThanOneOrganization =
    data && data.list.length > 1 && !isLoadingOrganizations;

  const { data: mainOrg, isLoading: isLoadingMainOrg } = useGetOrganization({
    id: self.organization.id,
    enabled: hasMoreThanOneOrganization,
  });

  const currentOrg = data?.list.find((org) => org?.id === currOrgId) || mainOrg;
  const chooseOrg = (orgId: string) => {
    deleteCookie('globalFilter');
    if (filters) {
      setFilters(null);
    }
    setCookie('organization_id', orgId, {
      maxAge: 60 * 60 * 24,
      path: '/',
      sameSite: 'strict',
    });
    queryClient.invalidateQueries();
    router.refresh();
  };

  const isLoading = isLoadingOrganizations || isLoadingMainOrg;

  if (!hasMoreThanOneOrganization && !isLoading) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='flex shrink-0 items-center justify-between gap-2 rounded-md border px-2 py-1 text-sm'>
        {isLoading ? 'Laddar...' : currentOrg?.name}{' '}
        <ChevronDownIcon className='size-4' />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start' className='w-72'>
        <DropdownMenuLabel>Välj organisation</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {isLoading && (
          <DropdownMenuItem className='flex w-full items-center justify-between'>
            <span className='text-xs text-muted-foreground'>
              Laddar organisationer...
            </span>
            <Icons.loader className='size-4 animate-spin' />
          </DropdownMenuItem>
        )}
        {!isLoading && (
          <>
            <DropdownMenuItem
              onClick={() => chooseOrg(self.organization.id)}
              className='flex w-full items-center justify-between'
            >
              <span>{mainOrg?.name}</span>{' '}
              {currOrgId === self.organization.id && (
                <CheckIcon className='size-4' />
              )}
            </DropdownMenuItem>
            {data?.list
              .filter((org) => org.id !== self.organization.id)
              .map((org) => (
                <DropdownMenuItem
                  className='flex w-full items-center justify-between pl-4'
                  key={org?.id}
                  onSelect={() => chooseOrg(org.id)}
                >
                  <span>{org.name}</span>
                  {org?.id === currOrgId && <CheckIcon className='size-4' />}
                </DropdownMenuItem>
              ))}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
