'use client';

import { getIsTrustedCustomer } from '@/components/intercom/intercom-wrapper';
import { Badge } from '@/components/ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useGetNotificationList } from '@/config/communication/notification/client';
import { useGetCookies } from '@/hooks/use-get-cookies';
import { useGetSelf } from '@/hooks/useGetSelf';
import { useLogout } from '@/hooks/useLogout';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import { BASE_BACKEND_URL } from '@/requests/constants';
import {
  ArrowLeftEndOnRectangleIcon,
  BellIcon,
  ChatBubbleLeftIcon,
  Cog6ToothIcon,
  LifebuoyIcon,
  RocketLaunchIcon,
} from '@heroicons/react/16/solid';
import { DateTime } from 'luxon';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Button, buttonVariants } from '../../../ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../ui/dropdown-menu';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTrigger,
} from '../../../ui/sheet';
import { NotificationSheetContent } from './components/notifications/notification-sheet-content';
import { ReleaseNotesheetContent } from './components/release-notes/release-notes-sheet-content';
import { useReleaseNoteReadStatus } from './components/release-notes/use-has-read-latest';

const SUPPORT_LINK = 'https://support.pigello.io';

export function FloatingMenu({ isSettings }: { isSettings?: boolean }) {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!window.Intercom) return;

    window.Intercom('onUnreadCountChange', (unread: number) =>
      setUnreadCount(unread)
    );
  }, []);

  const [sheetOpen, setSheetOpen] = useState(false);
  const { data: self } = useGetSelf();
  const { clearUserData } = useLogout();
  const { data: notifications } = useGetNotificationList({
    queryParams: {
      filters: {
        sent_to_organization_user: { __in: self?.id ?? '' },
        read: { noop: false },
      },
      page: 1,
      pageSize: 1,
    },
    enabled: !!self?.id,
  });
  const { cookies } = useGetCookies();

  const isTrustedCustomer = getIsTrustedCustomer(cookies?.customer_id);
  const isDev = BASE_BACKEND_URL?.includes('dev.api');

  const { hasReadLatest: hasReadLatestReleaseNotes, setLatestReadDate } =
    useReleaseNoteReadStatus();

  return (
    <>
      <div className='absolute bottom-0 z-50 h-auto w-full bg-gradient-to-t from-background to-transparent px-4 pb-4'>
        <div className='size-full overflow-hidden rounded-md  bg-gradient-to-r from-primary/30 via-border to-primary/30 p-px shadow-[10px_-24px_10px_-17px_rgba(0,0,0,0.06),0px_2px_4px_2px_rgba(81,101,251,0.08)]'>
          <div>
            <div
              className={cn(
                'flex w-full items-center justify-between  rounded-md bg-background px-4 py-2',
                {
                  'rounded-b-none': !isDev || isTrustedCustomer,
                }
              )}
            >
              <DropdownMenu>
                <Tooltip>
                  <DropdownMenuTrigger asChild>
                    <TooltipTrigger asChild>
                      {self?.profilePicture?.get ? (
                        <Image
                          src={self?.profilePicture?.get}
                          alt={self?.communicationName}
                          width={28}
                          height={28}
                          className='size-7 rounded-full border border-primary bg-cover bg-center object-cover object-center'
                        />
                      ) : (
                        <div className='flex size-7 items-center justify-center rounded-full border bg-primary bg-cover text-xs text-primary-foreground transition-colors hover:bg-primary/50'>
                          <span className='font-medium uppercase'>
                            {self?.firstName?.[0]}
                            {self?.lastName?.[0]}
                          </span>
                        </div>
                      )}
                    </TooltipTrigger>
                  </DropdownMenuTrigger>
                  <TooltipContent>Inställningar</TooltipContent>
                </Tooltip>
                <DropdownMenuContent align='start'>
                  {!isSettings && (
                    <DropdownMenuItem
                      className={cn(
                        'flex w-full items-center hover:cursor-default'
                      )}
                      asChild
                    >
                      <Link
                        prefetch={false}
                        className='flex items-center'
                        href={Urls.settings.profile.settings}
                      >
                        <Cog6ToothIcon width={20} className='mr-2' />
                        Inställningar
                      </Link>
                    </DropdownMenuItem>
                  )}

                  <DropdownMenuItem
                    onClick={() => clearUserData({ withReturnUrl: false })}
                    className='text-red-500'
                  >
                    <ArrowLeftEndOnRectangleIcon width={20} className='mr-2' />
                    Logga ut
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
                <Tooltip>
                  <SheetTrigger asChild>
                    <TooltipTrigger asChild>
                      <Button
                        className='relative'
                        variant={'outline'}
                        size={'icon'}
                      >
                        {notifications &&
                          notifications?.list?.some(
                            (notification) => !notification.read
                          ) && (
                            <span className='absolute -top-1 left-0.5 size-3 rounded-full border border-background bg-primary' />
                          )}
                        <BellIcon className='size-5 text-foreground' />
                      </Button>
                    </TooltipTrigger>
                  </SheetTrigger>
                  <TooltipContent>Notiser</TooltipContent>
                </Tooltip>
                <SheetContent className='flex flex-col gap-4 px-0'>
                  <SheetDescription className='sr-only'>
                    Alla slags användarnotiser
                  </SheetDescription>
                  <NotificationSheetContent
                    setSheetOpen={setSheetOpen}
                    userId={self?.id}
                  />
                </SheetContent>
              </Sheet>
              <Sheet>
                <Tooltip>
                  <SheetTrigger asChild>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() =>
                          setLatestReadDate(DateTime.now().toISO())
                        }
                        variant={'outline'}
                        className='relative'
                        size={'icon'}
                      >
                        {!hasReadLatestReleaseNotes && (
                          <span className='absolute -top-1 left-0.5 size-3 rounded-full border border-background bg-primary' />
                        )}
                        <RocketLaunchIcon className='size-5 text-foreground' />
                      </Button>
                    </TooltipTrigger>
                  </SheetTrigger>
                  <TooltipContent>Release notes</TooltipContent>
                </Tooltip>
                <SheetContent className='flex flex-col gap-4'>
                  <SheetDescription className='sr-only'>
                    Release notes gällande hela applikationen
                  </SheetDescription>
                  <ReleaseNotesheetContent />
                </SheetContent>
              </Sheet>

              <Tooltip>
                <TooltipTrigger>
                  <a
                    className={cn(
                      buttonVariants({ variant: 'outline', size: 'icon' })
                    )}
                    href={SUPPORT_LINK}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <LifebuoyIcon className='size-5 text-foreground' />
                  </a>
                </TooltipTrigger>
                <TooltipContent>Supportwebb</TooltipContent>
              </Tooltip>
            </div>

            {(!isDev || isTrustedCustomer) && (
              <button
                onClick={() =>
                  window.Intercom
                    ? window.Intercom('show')
                    : alert('Chatt ej initialiserad än, försök igen')
                }
                className='group flex w-full items-center justify-between rounded-b-md border-t bg-background px-4 py-1 text-sm text-secondary-foreground transition-all  hover:bg-primary hover:text-primary-foreground '
              >
                <div className='text-xs font-medium'>Supportchatt </div>
                <div className='flex items-center gap-2'>
                  {unreadCount > 0 && (
                    <Badge
                      className='animate-pulse px-1 text-xs leading-none'
                      variant='default'
                    >
                      {unreadCount}
                    </Badge>
                  )}
                  <ChatBubbleLeftIcon className=' size-4 ' />
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
