import { useFailedInvoices } from '@/app/dashboard/invoicing/failed-deliveries/utils';
import { IntegrationServiceDataMap } from '@/app/settings/organization/integrations/utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';
import { useMenu } from '@/hooks/useMenu';
import useMonitoringCounts from '@/hooks/useMonitoringCounts';
import { usePerms } from '@/hooks/usePerms';
import { useToast } from '@/hooks/useToast';
import { cn } from '@/lib/cn';
import { ObjectEntries, ObjectKeys } from '@/lib/utils';
import { useGetCount } from '@/requests/hooks';
import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Bars3Icon } from '@heroicons/react/16/solid';
import { InvoiceConfig } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import { menuData, type ISubMenu } from '../data';
import { reorder } from '../utils';
import SidebarBookmarks from './bookmarks';
import { SubMenu } from './sub-menu';
import type { CheckedState } from './types';

export function DashboardNavigation() {
  const { isEditMode, setIsEditMode, sidebarSettings, setSidebarSettings } =
    useMenu();
  const { addToast } = useToast();
  const {
    totalCount,
    totalCountLoading,
    performCount,
    approvalCount,
    retroactivityCount,
    totalPendingDistributionQuotas,
    pendingSigningCounts,
    performCountLoading,
    approvalCountLoading,
    retroactivityCountLoading,
    pendingDistributionQuotasLoading,
    pendingSigningCountsLoading,
  } = useMonitoringCounts();

  const { data: autogiroIntegrationData } = useGetCount({
    modelName: 'integrationuseragent',
    filters: {
      application_id: {
        noop: IntegrationServiceDataMap.AUTOGIRO?.applicationId,
      },
      is_active: { noop: true },
    },
  });
  const hasAnyAutogiroIntegration =
    !!autogiroIntegrationData && autogiroIntegrationData > 0;
  const { canView, isPending } = usePerms('invoice');
  const {
    data: pendingAutogiroTransactionAttempts,
    isLoading: pendingAutogiroTransactionAttemptsLoading,
  } = useGetCount({
    modelName: InvoiceConfig.modelName,
    filters: {
      autogiro_enabled: { noop: true },
      has_failed_autogiro_payment: { noop: false },
      has_successful_autogiro_payment: { noop: false },
      has_pending_autogiro_payment: { noop: true },
      attested_date: { __isnull: false },
      due_date: {
        __gte: DateTime.now().minus({ days: 15 }).toISODate(),
      },
    },
    enabled: hasAnyAutogiroIntegration && canView && !isPending,
  });
  const {
    data: failedAutogiroTransactionAttempts,
    isLoading: failedAutogiroTransactionAttemptsLoading,
  } = useGetCount({
    modelName: InvoiceConfig.modelName,
    filters: {
      autogiro_enabled: { noop: true },
      has_pending_autogiro_payment: { noop: false },
      has_failed_autogiro_payment: { noop: true },
      has_successful_autogiro_payment: { noop: false },
    },
    enabled: hasAnyAutogiroIntegration && canView && !isPending,
  });

  const { data: failedDeliveriesCount, isLoading: failedDeliveriesLoading } =
    useFailedInvoices(true);

  const notifications = {
    ['monitor-inbox']: totalCountLoading ? totalCountLoading : totalCount,
    ['monitored-performs']: performCountLoading
      ? performCountLoading
      : performCount,
    ['monitored-proposals']: approvalCountLoading
      ? approvalCountLoading
      : approvalCount,
    ['retroactivity']: retroactivityCountLoading
      ? retroactivityCountLoading
      : retroactivityCount,
    ['distribution-quotas']: pendingDistributionQuotasLoading
      ? pendingDistributionQuotasLoading
      : totalPendingDistributionQuotas,
    ['pending-signings']: pendingSigningCountsLoading
      ? pendingSigningCountsLoading
      : pendingSigningCounts,
    ['rent']:
      (failedAutogiroTransactionAttempts || 0) +
      (pendingAutogiroTransactionAttempts || 0) +
      (failedDeliveriesCount?.count || 0),
    ['autogiropending']: pendingAutogiroTransactionAttemptsLoading
      ? pendingAutogiroTransactionAttemptsLoading
      : pendingAutogiroTransactionAttempts,
    ['autogirofailed']: failedAutogiroTransactionAttemptsLoading
      ? failedAutogiroTransactionAttemptsLoading
      : failedAutogiroTransactionAttempts,
    ['faileddeliveries']: failedDeliveriesLoading
      ? failedDeliveriesLoading
      : (failedDeliveriesCount?.count ?? 0),
  };

  const handleDragEnd = (result: DropResult, key: string) => {
    if (!result.destination) return;
    const curr =
      sidebarSettings.menuOrder?.[key] ??
      menuData[key as keyof typeof menuData].subMenus.map((item) => item.key);

    const newItems = reorder(
      curr,
      result.source.index,
      result.destination.index
    );
    setSidebarSettings((prev) => ({
      ...prev,
      menuOrder: {
        ...prev.menuOrder,
        [key]: newItems,
      },
    }));
  };

  const handleRootDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === 0) {
      return addToast({
        title: 'Meny måste vara högst upp',
        type: 'info',
      });
    }
    const curr =
      sidebarSettings.menuOrder?.['root'] ??
      ObjectKeys(menuData).map((key) => key);
    const newItems = reorder(
      curr,
      result.source.index,
      result.destination.index
    );
    setSidebarSettings((prev) => ({
      ...prev,
      menuOrder: {
        ...prev.menuOrder,
        root: newItems,
      },
    }));
  };

  const handleParentIsMenuVisible = (
    checked: CheckedState,
    subMenu: ISubMenu[],
    key: string
  ) => {
    const newState: Record<string, boolean> = {
      ...sidebarSettings.menuVisibility,
      [key]: !checked,
      ...subMenu.reduce((acc, { key }) => ({ ...acc, [key]: !checked }), {}),
      ...subMenu.reduce(
        (acc, { subMenus }) =>
          subMenus?.reduce(
            (acc, { key }) => ({ ...acc, [key]: !checked }),
            acc
          ) ?? acc,
        {}
      ),
    };
    setSidebarSettings((prev) => ({
      ...prev,
      menuVisibility: newState,
    }));
  };

  const filteredMenuData = Object.fromEntries(
    ObjectEntries(menuData).map(([key, value]) => {
      // Filter out autogiro related menus from the rent submenu
      const filteredSubMenus = value.subMenus?.map((submenu) => {
        if (submenu.key === 'rent') {
          return {
            ...submenu,
            subMenus: submenu.subMenus?.filter(
              (rentSubmenu) =>
                hasAnyAutogiroIntegration ||
                (rentSubmenu.key !== 'autogirofailed' &&
                  rentSubmenu.key !== 'autogiropending')
            ),
          };
        }
        return submenu;
      });

      return [
        key,
        {
          ...value,
          subMenus: filteredSubMenus,
        },
      ];
    })
  );

  return (
    <>
      <DragDropContext onDragEnd={handleRootDragEnd}>
        <Droppable isDropDisabled={!isEditMode} droppableId={'root'}>
          {(provided) => (
            <Accordion
              ref={provided.innerRef}
              {...provided.droppableProps}
              defaultValue={[
                'userMenu',
                'objects',
                'modules',
                'processes',
                'bookmarks',
              ]}
              onValueChange={(values) => {
                setSidebarSettings((prev) => ({
                  ...prev,
                  expandedItems: {
                    ...prev.expandedItems,
                    root: values,
                  },
                }));
              }}
              value={sidebarSettings.expandedItems?.['root']}
              className={cn('w-full pr-4', !isEditMode && 'pl-4')}
              type='multiple'
            >
              {ObjectEntries(filteredMenuData)
                .filter(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  ([_, { key, isIndex }]) =>
                    isIndex ||
                    !(isEditMode ? {} : sidebarSettings.menuVisibility)?.[key]
                )
                .sort(
                  (a, b) =>
                    sidebarSettings.menuOrder?.['root']?.indexOf(a[0]) -
                    sidebarSettings.menuOrder?.['root']?.indexOf(b[0])
                )
                .map(
                  (
                    [groupType, { title, subMenus, isIndex, key: parentKey }],
                    idx
                  ) => (
                    <Draggable
                      index={idx}
                      isDragDisabled={!isEditMode || isIndex}
                      draggableId={title + groupType + parentKey}
                      key={groupType}
                    >
                      {(provided) => (
                        <>
                          <AccordionItem
                            value={groupType}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className='w-full border-none'
                          >
                            {isIndex ? (
                              <div
                                className={cn(
                                  'group/menu mt-1 flex items-center justify-between pl-6',
                                  !isEditMode && 'pl-2'
                                )}
                              >
                                <h4
                                  className={cn(
                                    'flex items-center gap-2 py-1 text-xs font-normal text-foreground/90',
                                    sidebarSettings.menuVisibility?.[
                                      parentKey
                                    ] &&
                                      !isIndex &&
                                      'opacity-50'
                                  )}
                                >
                                  {title}
                                </h4>
                                <div className='flex items-center gap-2'>
                                  {isEditMode && (
                                    <button
                                      className={cn(
                                        'hidden text-xs text-muted-foreground transition-colors hover:text-foreground group-hover/menu:block',
                                        isEditMode && 'block'
                                      )}
                                      onClick={() =>
                                        setSidebarSettings({
                                          menuOrder: {},
                                          menuVisibility: {},
                                          expandedItems: {},
                                        })
                                      }
                                    >
                                      Återställ
                                    </button>
                                  )}
                                  <button
                                    className={cn(
                                      'hidden text-xs text-muted-foreground transition-colors hover:text-foreground group-hover/menu:block',
                                      isEditMode && 'block'
                                    )}
                                    onClick={() =>
                                      setIsEditMode((prev) => !prev)
                                    }
                                  >
                                    {isEditMode ? 'Klar' : 'Redigera'}
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className='group/root-item flex w-full items-start'>
                                {isEditMode && (
                                  <button
                                    {...provided.dragHandleProps}
                                    className={cn(
                                      'invisible ml-1 mt-3 cursor-grab transition-opacity group-hover/root-item:visible'
                                    )}
                                  >
                                    <Bars3Icon className='size-4' />
                                  </button>
                                )}
                                <div
                                  className={cn(
                                    'mt-2 flex w-full items-center rounded py-1 pr-2 text-left text-sm text-secondary-foreground transition-colors hover:bg-muted-foreground/10'
                                  )}
                                >
                                  <AccordionTrigger
                                    icon={!isEditMode}
                                    className={cn(
                                      ' p-0 text-left  text-foreground/90 hover:no-underline [&>svg]:opacity-0 [&>svg]:transition-all [&>svg]:hover:opacity-100',
                                      sidebarSettings.menuVisibility?.[
                                        parentKey
                                      ] &&
                                        !isIndex &&
                                        'opacity-50'
                                    )}
                                  >
                                    <span className='w-full pl-2 text-xs font-normal'>
                                      {title}
                                    </span>
                                  </AccordionTrigger>
                                  {isEditMode && !isIndex && (
                                    <Checkbox
                                      checked={
                                        !sidebarSettings.menuVisibility?.[
                                          parentKey
                                        ] && !isIndex
                                      }
                                      onCheckedChange={(checked) =>
                                        handleParentIsMenuVisible(
                                          checked,
                                          subMenus,
                                          parentKey
                                        )
                                      }
                                      className='size-4'
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            <AccordionContent>
                              <DragDropContext
                                onDragEnd={(e) => handleDragEnd(e, parentKey)}
                              >
                                <Droppable
                                  type={parentKey}
                                  droppableId={groupType}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {subMenus
                                        .filter(
                                          ({ key }) =>
                                            !(
                                              isEditMode
                                                ? {}
                                                : sidebarSettings.menuVisibility
                                            )?.[key]
                                        )
                                        .sort(
                                          (a, b) =>
                                            sidebarSettings.menuOrder?.[
                                              parentKey
                                            ]?.indexOf(a.key) -
                                            sidebarSettings.menuOrder?.[
                                              parentKey
                                            ]?.indexOf(b.key)
                                        )
                                        .map((menu, idx) => (
                                          <SubMenu
                                            key={menu.key}
                                            index={idx}
                                            parentKey={parentKey}
                                            menu={menu}
                                            subMenus={subMenus}
                                            notifications={notifications}
                                          />
                                        ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <div className='mx-2 mt-3 border-t border-border pb-1 ' />
                            </AccordionContent>
                          </AccordionItem>
                        </>
                      )}
                    </Draggable>
                  )
                )}
              {provided.placeholder}
            </Accordion>
          )}
        </Droppable>
        <SidebarBookmarks />
      </DragDropContext>
    </>
  );
}
