import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useSystemHealth } from '@/hooks/use-system-health';
import { cn } from '@/lib/cn';
import { toReadableDateTime } from '@/lib/dates';
import { XMarkIcon } from '@heroicons/react/16/solid';

export default function SystemStatus({
  closeFunction,
}: {
  closeFunction: () => void;
}) {
  const { data: systemHealth } = useSystemHealth();

  return (
    <Dialog defaultOpen onOpenChange={closeFunction}>
      <DialogContent className='p-4 sm:max-w-md'>
        <DialogHeader className='flex flex-col items-start gap-2 border-b-0 p-0'>
          <div className='flex w-full items-center justify-between'>
            <DialogTitle className='flex items-center gap-2'>
              <div
                className={cn(`size-3 rounded-full`, {
                  'bg-green-500':
                    !systemHealth?.maintenance?.active &&
                    !systemHealth?.maintenance?.eta,
                  'bg-yellow-600': !!systemHealth?.maintenance?.eta,
                  'bg-red-500': systemHealth?.maintenance?.active,
                })}
              />
              Systemstatus
            </DialogTitle>

            <Button variant={'secondary'} onClick={closeFunction} size={'icon'}>
              <XMarkIcon className='size-4' />
            </Button>
          </div>
          {systemHealth?.maintenance?.eta && (
            <DialogDescription>
              Planerat underhåll:{' '}
              {toReadableDateTime(systemHealth?.maintenance?.eta)}
            </DialogDescription>
          )}

          {!systemHealth?.maintenance?.eta && (
            <DialogDescription>Alla system är operativa.</DialogDescription>
          )}
        </DialogHeader>

        <div className='mt-4 flex flex-col gap-2'>
          <Button onClick={closeFunction} variant={'outline'}>
            Stäng
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
