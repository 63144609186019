'use client';

import type { HealthStatus } from '@/app/(auth)/types';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { useQuery } from '@tanstack/react-query';

export const useSystemHealth = () => {
  return useQuery<HealthStatus>({
    queryKey: ['system-health'],
    queryFn: async () => {
      const response = await fetch(`${BASE_BACKEND_URL}/common/health/`);
      const data = await response.json();

      return data as HealthStatus;
    },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
};
