import { SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import { RELEASE_NOTES } from './constants';
import ReleaseNote from './release-note';

export interface IReleaseNote {
  date: DateTime;
  title: string;
  content: ReactNode;
}

export function ReleaseNotesheetContent() {
  return (
    <>
      <SheetHeader className='flex flex-row items-center justify-between border-b pb-4'>
        <SheetTitle className='flex w-full items-center space-x-2'>
          <div className='mr-auto'>Release notes</div>
        </SheetTitle>
      </SheetHeader>

      <div className='flex h-full flex-col gap-6 pt-4'>
        {RELEASE_NOTES.sort(
          (a, b) => b.date.toUnixInteger() - a.date.toUnixInteger()
        ).map((note) => (
          <ReleaseNote key={note.date.toUnixInteger()} note={note} />
        ))}
      </div>
    </>
  );
}
